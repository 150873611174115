<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Client</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Client List</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-card type="default" no-body class="client-list-table mb-2">
              <b-form style="text-align: left; padding: 10px;">
                <b-tabs pills>
                  <b-tab active>
                    <template v-slot:title>
                      New Client Entry Filter
                    </template>
                    <b-row class="mb-2 mt--10">
                      <b-col md="3">
                          <label class="mt-2">Start Date</label>
                          <b-input v-model="startDateEntry" type="date" value="dd-mm-yyyy"/>
                      </b-col>
                      <b-col md="3">
                          <label class="mt-2">End Date</label>
                          <b-input v-model="endDateEntry" type="date" value="dd-mm-yyyy"/>
                      </b-col>
                      <b-col md="3">
                          <label class="mt-2">Employee</label>
                          <b-form-select v-model="empTypeEntry" :options="empType"></b-form-select>
                      </b-col>
                      <b-col md='3'>
                          <label class="mt-2">Click Here</label>
                          <base-button block type="primary" @click="searchTableEntry()">Filter</base-button>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                      Follow up Filter
                    </template>
                    <b-row class="mb-2 mt--10">
                      <b-col md="3">
                          <label class="mt-2">Start Date</label>
                          <b-input v-model="startDateFollow" type="date" value="dd-mm-yyyy"/>
                      </b-col>
                      <b-col md="3">
                          <label class="mt-2">End Date</label>
                          <b-input v-model="endDateFollow" type="date" value="dd-mm-yyyy"/>
                      </b-col>
                      <b-col md="3">
                          <label class="mt-2">Employee</label>
                          <b-form-select v-model="empTypeFollow" :options="empType"></b-form-select>
                      </b-col>
                      <b-col md='3'>
                          <label class="mt-2">Click Here</label>
                          <base-button block type="primary" @click="searchTableFollow()">Filter</base-button>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                      Product Filter
                    </template>
                    <b-row class="mb-2 mt--10">
                      <b-col md="3">
                          <label class="mt-2">Product</label>
                          <b-form-select v-model="product" :options="proDropdown"></b-form-select>
                      </b-col>
                      <b-col md="3">
                          <label class="mt-2">Subproduct</label>
                          <b-form-select v-model="subproduct" :options="subProDropdown"></b-form-select>
                      </b-col>
                      <b-col md='3'>
                          <label class="mt-2">Click Here</label>
                          <base-button block type="primary" @click="searchTableProduct()">Filter</base-button>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                      Birthday Filter
                    </template>
                    <b-row class="mb-2 mt--10">
                      <b-col md="3">
                          <label class="mt-2">Start Date</label>
                          <b-input v-model="startDateBirth" :date-format-options="{month: 'numeric', day: 'numeric'}" type="date" value="dd-mm"/>
                      </b-col>
                      <b-col md="3">
                          <label class="mt-2">End Date</label>
                          <b-input v-model="endDateBirth" type="date" value="dd-mm"/>
                      </b-col>
                      <b-col md='3'>
                          <label class="mt-2">Click Here</label>
                          <base-button block type="primary" @click="searchTableBirth()">Filter</base-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-form>
            </b-card>
            <b-row  class="mb-2">
              <b-col md="10"></b-col>
              <b-col md="2"><base-button block type="primary" @click="csvExport(csvData)">Export to CSV</base-button></b-col>
            </b-row>
            <b-card type="default" no-body class="client-list-table">
                <pulse-loader v-if="loding" :color="color" :size="size" />
                <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                enabled: true,
                placeholder: 'Search this table',
                }"
                :pagination-options="{
                enabled: true,
                mode: 'records'
                }"
                >
                  <template slot="table-row" slot-scope="props" style="w">
                    <span v-if="props.column.field == 'after'">
                        <base-button
                        icon type="primary"
                        size="sm"
                        @click="$router.push({name: 'client-documents',params:{id: props.row.client_id}});"
                        >
                        <span class="btn-inner--icon"><i class="far fa-file-image"></i></span>
                        </base-button>
                        <base-button
                        icon type="primary"
                        size="sm"
                        @click="$router.push({name: 'client-products',params:{id: props.row.client_id}});"
                        >
                        <span class="btn-inner--icon">
                          <i class="fas fa-list-alt"></i>
                        </span>
                        </base-button>
                        <base-button
                        icon type="danger"
                        size="sm"
                        @click="checkedClick(props.row.client_id)"
                        >
                        <span class="btn-inner--icon">
                          <i class="ni ni-check-bold" v-if="props.row.client_status"></i>
                          <i class="ni ni-fat-remove" v-else></i>
                        </span>
                        </base-button>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
            </b-card>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api';
export default {
  name: 'client-list',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: true,
      color: '#5e72e4',
      size: '30px',
      margin: '2px',
      radius: '2px',
      loding: true,
      startDateEntry: null,
      endDateEntry: null,
      empTypeEntry: null,
      startDateFollow: null,
      endDateFollow: null,
      empTypeFollow: null,
      startDateBirth: null,
      endDateBirth: null,
      product: null,
      subproduct: null,
      columns: [
        {
          label: 'Name',
          field: 'client_name'
        },
        {
          label: 'Client Type',
          field: 'client_type'
        },
        {
          label: 'Employee Name',
          field: 'employee_name'
        },
        {
          label: 'Email',
          field: 'client_email'
        },
        {
          label: 'Phone No',
          field: 'client_phone',
          type: 'number'
        },
        {
          label: 'Mobile No',
          field: 'client_phone_other',
          type: 'number'
        },
        {
          label: 'Gender',
          field: 'client_gender'
        },
        {
          label: 'Client Dob',
          field: 'client_dob'
        },
        {
          label: 'Refarence',
          field: 'client_ref'
        },
        {
          label: 'profession',
          field: 'client_profession'
        },
        {
          label: 'Address',
          field: 'full_address'
        },
        {
          label: 'Landmark',
          field: 'client_landmark'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ],
      rows: [] 
    }
  },
  computed: {
    csvData () {
      return this.rows.map(item => ({
        ...item
      //  address: 'адрес', // item.address.city,
      //  company: 'компания'// item.company.name
      }))
    },
    // rows () {
    //   return this.$store.state.clientList
    // },
    empType() {
      return this.$store.state.employeeDropdown
    },
    proDropdown() {
      return this.$store.state.productDropdown
    },
    subProDropdown() {
      return (this.$store.state.subproductDropdown ? this.$store.state.subproductDropdown.filter((subproData) =>{
        return subproData.pro_id == this.product
      }) : [])
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    clientTable() {
      this.axios.get(API_URL+'admin_client_list')
      .then((response) => {
      let obj = response.data
      this.rows = obj
      this.loding = false
      })
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.client_id === id) {
          row.client_status = !row.client_status; 
          let active = row.client_status;
          this.axios.post(API_URL+'admin_client_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Oops...',
                  text: `${message}`,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
            }else {
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              })
            }
          })
        }
        return row
      })
    },
    csvExport (arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'employee-list.csv')
      link.click()
    },
    searchTableEntry() {
      this.rows = []
      const self = this;
      let data = {
        callback() {
          self.loding = true
          let data = {startDateEntry: self.startDateEntry,endDateEntry: self.endDateEntry,empTypeEntry: self.empTypeEntry};
          self.axios.post(API_URL+'admin_client_list_entry',data)
          .then((response) => {
          let obj = response.data
          console.log(obj);
          self.rows = obj
          self.loding = false
          })
          self.startDateEntry = null
          self.endDateEntry = null
          self.empTypeEntry = null
        }        
      }
      const validation =  this.startDateEntry == null ? this.$swal.fire({icon: 'error',title: 'Start Date Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.endDateEntry == null ? this.$swal.fire({icon: 'error',title: 'End Date Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): data.callback()
    },
    searchTableFollow() {
      this.rows = []
      const self = this;
      let data = {
        callback() {
          self.loding = true
          let dataFolllo = {startDateFollow: self.startDateFollow,endDateFollow: self.endDateFollow,empTypeFollow: self.empTypeFollow};
          self.axios.post(API_URL+'admin_client_list_follow',dataFolllo)
          .then((response) => {
          let obj = response.data
          console.log(obj);
          self.rows = obj
          self.loding = false
          })
          self.startDateFollow = null
          self.endDateFollow = null
          self.empTypeFollow = null
        }        
      }
      const validation =  this.startDateFollow == null ? this.$swal.fire({icon: 'error',title: 'Start Date Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.endDateFollow == null ? this.$swal.fire({icon: 'error',title: 'End Date Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): data.callback()
    },
    searchTableProduct() {
      this.rows = []
      const self = this;
      let data = {
        callback() {
          self.loding = true
          let dataparam = {product: self.product,subproduct: self.subproduct};
          self.axios.post(API_URL+'admin_client_list_product',dataparam)
          .then((response) => {
          let obj = response.data
          console.log(obj);
          self.rows = obj
          self.loding = false
          })
          self.product = null
          self.subproduct = null
        }        
      }
      const validation =  this.product == null ? this.$swal.fire({icon: 'error',title: 'Product Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): data.callback()
    },
    searchTableBirth() {
      const start = new Date(this.startDateBirth);
      const end = new Date(this.endDateBirth);
      const startDateBirth = `${start.getMonth()+1}`+'-'+start.getDate()
      const endDateBirth = `${end.getMonth()+1}`+'-'+end.getDate()
      this.rows = []
      const self = this;
      let data = {
        callback() {
          self.loding = true
          let dataparam = {startDateEntry: startDateBirth,endDateEntry: endDateBirth};
          self.axios.post(API_URL+'admin_client_list_dob',dataparam)
          .then((response) => {
          let obj = response.data
          console.log(obj);
          self.rows = obj
          self.loding = false
          })
          self.startDateBirth = null
          self.endDateBirth = null
        }        
      }
      const validation =  this.startDateBirth == null ? this.$swal.fire({icon: 'error',title: 'Start Date Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): this.endDateBirth == null ? this.$swal.fire({icon: 'error',title: 'End Date Empty',toast: true,
                  position: 'top-end',showConfirmButton: false,timer: 3000,timerProgressBar: true,
                }): data.callback()
    }
  },
  mounted() {
    this.clientTable()
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
.client-list-table {
    table.vgt-table {
        font-size: 12px;
    }
}
</style>
